import React from 'react';
import styled from 'styled-components'
import { ImgStyle, StyledNavLink } from "../style";
import { Text } from "../text";
import { FlexGap } from "../flex";
import logo from "../../assets/logo.png";
import tg from "../../assets/tg.png";
import tw from "../../assets/tw.png";
import { goLink } from "../../utils/link";

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 30px 48px 0 20px;
    box-sizing: border-box;
    z-index: 999;
    position: relative;
    @media screen and (max-width: 680px){ 
        flex-direction: column;
        gap: 10px;
        padding: 20px 20px 0 20px;
    }
`
const Menu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 200px;
    border-radius: 36px;
    background: #E9E010;
    padding: 12px 38px 8px;
    @media screen and (max-width: 1130px){ 
        gap: 50px;
    }
    @media screen and (max-width: 680px){ 
        flex: 1;
        gap: 20px;
        padding: 12px 10px 8px;
    }
`
const TextHide =styled(Text)`
    @media screen and (max-width: 970px){ 
        display: none;
    }
`

function Header(params) {
    return(
        <Wrapper>
            <Menu>
                <StyledNavLink to={'/home'}>
                    <FlexGap gap={8}>
                        <ImgStyle src={logo} width={56} height={56} />
                        <TextHide color={'#000'} size={16}>Gentle Dragon</TextHide>
                    </FlexGap>
                </StyledNavLink>
                <FlexGap gap={30} hgap={10}>
                    <StyledNavLink to={'/about'}>
                        <Text color={'#000'} size={16} hsize={12}>About Us</Text>
                    </StyledNavLink>
                    <StyledNavLink to='https://opensea.io/collection/gentle-dragon'>
                        <Text color={'#000'} size={16} hsize={12}>NFT Collection</Text>
                    </StyledNavLink>
                    <StyledNavLink to={'/cummunity'}>
                        <Text color={'#000'} size={16} hsize={12}>Cummunity</Text>
                    </StyledNavLink>
                </FlexGap>
            </Menu>
            <FlexGap gap={20}>
                <ImgStyle onClick={() => {goLink('twitter')}} src={tw} width={42} height={42} />
                <ImgStyle onClick={() => {goLink('tg')}} src={tg} width={42} height={42} />
            </FlexGap>
        </Wrapper>
    )
}

export default Header