import React from 'react';
import styled, { keyframes } from 'styled-components'
import Header from "../../components/header";
import { Flex, FlexGap, SpaceBetween } from '../../components/flex';
import { Text } from '../../components/text';
import { ImgStyle, ContentBox } from '../../components/style';
import uni from "../../assets/uni.png";
import dragon6 from "../../assets/dragon6.png";
import dragon7 from "../../assets/dragon7.png";
import line from "../../assets/line.png";
import dragonIcon from "../../assets/dragonIcon.png";
import ImageSequence from "../../components/ImageSequence";
import Rolls from "../../components/rolls";
import Lamp from "../../components/lamp";

const Wrapper = styled.div`
    width: 100%;
    padding: 6px 0 72px;
    z-index: 9;
    overflow: hidden;
`
const LampBox= styled.div`
    width: 100%;
    position: relative;
`
const HomePrimary = styled.div`
    width: 98%;
    margin: 6px auto 0;
    border-radius: 60px;
    background: #101010; 
    box-sizing: border-box;
    position: relative;
    z-index: 97;
`
const TextBox = styled.div`
    margin-top: 10vh;
    margin-bottom: 50px;
    @media screen and (max-width: 1440px){ 
        margin-top: 6vh;
        margin-bottom: 24px;
    }
    @media screen and (max-width: 540px){ 
        margin-top: 3vh;
        margin-bottom: 24px;
    }
`
const TextPrimary = styled.div`
    text-shadow: 6px 7px 0px #1F2024;
    font-size: 75px;
    line-height: 90px;
    letter-spacing: 6px;
    text-transform: uppercase;
    @media screen and (max-width: 1420px){ 
        font-size: 40px;
        line-height: 75px;
    }
    @media screen and (max-width: 540px){ 
        font-size: 25px;
        line-height: 50px;
    }
`
const Buttom = styled.div`
    display: inline-block;
    padding: 12px 18px;
    margin-bottom: 46px;
    border-radius: 20px;
    background: #E9E010;
    cursor: pointer;
`
const Height = styled.div`
    overflow: hidden;
    height: 476px;
    @media screen and (max-width: 1440px){ 
        height: 376px;
    }
    @media screen and (max-width: 540px){ 
        height: 200px;
    }
`
const BottomLine = styled.div`
    height: 72px;
    position: relative;
    @media screen and (max-width: 1400px){ 
        height: 60px;
    }
    @media screen and (max-width: 800px){ 
        height: 48px;
    }
`
const ColorLine = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 10px;
    position: absolute;
    top: 0;
    opacity: 0.2;
    background: linear-gradient(180deg, #FEF102 0%, #FFA108 100%);
    z-index: 98;
    transform: rotate(-3.181deg);
`
const TextLine = styled.div`
    height: 100%;
    // background-image: url(${line});
    background-size: 100% 100%;
    z-index: 99;
    // margin-top: -2px;
    background: linear-gradient(180deg, #FEF102 0%, #FFA108 100%);
    @media screen and (max-width: 1000px){ 
        background-position: center;
        background-size: auto 100%;
    }
`
const slide = keyframes`
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 14px 0;
    display: flex;
    align-items: center;
    column-gap: 25px;
    box-sizing: border-box;
    animation: ${slide} 20s linear infinite;
`
const ContainerImg = styled.img`
    width: auto;
    height: 100%;
`

const DragonTitle= styled.div`
    margin: 72px 0 90px;
`
const YesrFlexGap = styled(FlexGap)`
    display: flex;
    align-items: center;
    gap: 120px;
    @media screen and (max-width: 820px){ 
        flex-direction: column;
        gap: 20px;
    }
`
const DragonDetail= styled.div`
    flex: 1;
`
const Sculpture = styled.div`
    position: relative;
    width: 340px;
    height: 358px;
    @media screen and (max-width: 520px){ 
        width: 200px;
        height: 218px;
    }
`
const ImgStyleIndex = styled.img`
    width: 340px;
    height: 340px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    @media screen and (max-width: 520px){ 
        width: 200px;
        height: 200px;
    }
`
const ImgShadow = styled.div`
    position: absolute;
    top: 18px;
    left: 18px;
    width: 340px;
    height: 340px;
    background: #E9E010;
    border-radius: 22px;
    @media screen and (max-width: 520px){ 
        width: 200px;
        height: 200px;
    }
`
const RollBox = styled.div`
    padding: 100px 0;
`
const TextBottom = styled.div`
    margin-bottom: ${({b}) => b ? b + 'px' : '46px'};
`
const Tokenomics = styled(Flex)`
    padding: 42px 0 24px 62px;
    border-radius: 30px;
    background: #E9E010;
    z-index: 97;
    position: relative;
    align-items: flex-end;
    @media screen and (max-width: 520px){ 
        flex-direction: column;
        padding: 20px;
    }
`
const ItemText = styled.div`
    width: 70%;
    @media screen and (max-width: 520px){ 
        width: 100%;
    }
`
const ItemImg = styled.div`
    width: 30%;
    display: flex;
    align-items: flex-end;
    justify-content: end;
`
const ItemImgStyle = styled.img`
    width: 80%;
    height: auto;
`
const ContentWidth = styled.div`
    width: 100%;
    margin: 70px 0 56px;
    background: #000;
    box-shadow: 0px -11px 9px 0px #101010;
    z-index: 97;
    position: relative;
`
const ContentWidthIn = styled.div`
    max-width: 1520px;
    padding: 170px 20px 0;
    margin: 0 auto;
`


function Home(params) {
    return(
        <Wrapper>
            <LampBox>
                <HomePrimary>
                    <Header />
                    <TextBox className={'animate__animated animate__zoomInDown animate__delay-0.6s'}>
                        <TextPrimary>
                            Welcome to
                        </TextPrimary>
                        <TextPrimary>
                            Gentle Dragon Home
                        </TextPrimary>
                    </TextBox>
                    <Buttom className={'animate__animated animate__fadeIn animate__delay-1s'}>
                        <Flex>
                            <Text size={20} color={'#1F2024'}>Uninswap</Text>
                            <ImgStyle src={uni} width={36} height={36} />
                        </Flex>
                    </Buttom>
                    <Height>
                        <ImageSequence />
                    </Height>
                </HomePrimary>
                <Lamp style={{ top: '-40%', right: '-20%' }} />
                <Lamp style={{ left: '0', bottom: '-8%' }} />
                <Lamp style={{ right: '-10%', bottom: '-35%' }} />
            </LampBox>
            <BottomLine>
                <TextLine>
                    <Container>
                        {
                            Array.from({length: 18}, (_, i) => (
                                <ContainerImg key={i} src={dragonIcon} />
                            ))
                        }
                    </Container>
                </TextLine>
                <ColorLine />
            </BottomLine>
            <ContentBox>
                <DragonTitle>
                    <Text size={44} hsize={22} color={'#E9E010'}>Year of</Text>
                    <Text size={44} hsize={22} color={'#E9E010'}>Gentle Dragon</Text>
                </DragonTitle>
                <YesrFlexGap>
                    <Sculpture>
                        <ImgStyleIndex src={dragon6} width={340} height={340} />
                        <ImgShadow />
                    </Sculpture>
                    <DragonDetail>
                        <Text size={14} color={'#fff'} justify={true}>
                        In this rapidly changing digital era, the spirit of individual expression 
                        and freedom has become the core pursuit for many. In response to this trend,
                         we introduce the Gentle Dragon NFT series, inspired by the Bored Ape Yacht 
                         Club project. Similar to their approach, we've created a collection of dragons 
                         with a combination of various features and attributes, resulting in a series of 
                         unique and captivating dragon avatars. These distinctive dragon avatars serve 
                        not only as expressions of art but also as declarations of personality and freedom.
                        </Text>
                    </DragonDetail>
                </YesrFlexGap>
            </ContentBox>
            <RollBox>
                <TextBottom>
                    <Text size={44} hsize={22} color={'#E9E010'}>Art Gallery</Text>
                </TextBottom>
                <Rolls />
            </RollBox>
            <LampBox>
                <ContentBox>
                    <TextBottom>
                        <Text size={44} hsize={22} color={'#E9E010'}>Tokenomics</Text>
                    </TextBottom>
                    <Tokenomics>
                        <ItemText>
                            <TextBottom b={30}>
                                <Text size={18} color={'#000'} justify={true}>Economics</Text>
                            </TextBottom>
                            <TextBottom b={20}>
                                <Text size={16} color={'#000'} justify={true}>
                                    Total Supply:<br/>
                                    1,000,000,000 $G-Dragon
                                </Text>
                            </TextBottom>
                            <TextBottom b={20}>
                                <Text size={16} color={'#000'} justify={true}>
                                    Max Supply:<br/>
                                    1,000,000,000 $G-Dragon<br/>
                                </Text>
                            </TextBottom>
                            <TextBottom b={20}>
                                <Text size={16} color={'#000'} justify={true}>
                                    Distribution: 80% tokens added to the Liquidity Pool (LP). Allocating 10% 
                                    to NFT holders through an airdrop and earmarking another 10% for future 
                                    listings on centralized exchanges, bridging, and liquidity pools.
                                </Text>
                            </TextBottom>
                            <TextBottom b={20}>
                                <Text size={16} color={'#000'} justify={true}>
                                    LP Tokens: Burned to ensure fairness and security.
                                </Text>
                            </TextBottom>
                            <TextBottom b={20}>
                                <Text size={16} color={'#000'} justify={true}>
                                Ownership: Fully community-owned; project is decentralized with no central authority.
                                </Text>
                            </TextBottom>
                        </ItemText>
                        <ItemImg>
                            <ItemImgStyle src={dragon7} />
                        </ItemImg>
                    </Tokenomics>
                </ContentBox>
                <Lamp style={{ top: '-90%', left: '0' }} />
                <Lamp style={{ bottom: '-20%', right: '0' }} />
            </LampBox>
            <ContentWidth>
                <ContentWidthIn>
                    <TextBottom b={168}>
                        <Text size={70} hsize={40} color={'#E9E010'}>JOIN US</Text>
                    </TextBottom>
                    <Text size={16} color={'#fff'} justify={true}>COPYRIGHT © 2024 ALL RIGHTS RESERVED GENTLE DRAGON</Text>
                    
                </ContentWidthIn>
            </ContentWidth>
        </Wrapper>
    )
}

export default Home