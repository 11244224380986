import React from 'react';
import styled from 'styled-components';

const LampStyle = styled.div`
    width: ${({width}) => width+ 'px'};
    height: ${({height}) => height+ 'px'};
    border-radius: 189px;
    opacity: .8;
    background: var(--15, linear-gradient(136deg, #FEF102 0%, #FFA108 98.76%));
    filter: blur(200.5px);
    position: absolute;
    z-index: 90;
    @media screen and (max-width: 520px){ 
        width: 278px;
        height: 400px;
        filter: blur(200.5px);
    }
`
const Lamp = ({
    w=378,
    h=563,
    style
}) => {
    return(
        <LampStyle width={w} height={h} style={style}>
        </LampStyle>
    )
}

export default Lamp