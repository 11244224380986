
import React from 'react';
import styled from 'styled-components'
import { Text } from '../../components/text';
import { ImgStyle, ContentBox } from '../../components/style';
import dragon7 from "../../assets/dragon7.png";
import dragon8 from "../../assets/dragon8.png";
import dragon9 from "../../assets/dragon9.png";
import dragon10 from "../../assets/dragon10.png";
import dragon11 from "../../assets/dragon11.png";
import Lamp from "../../components/lamp";
import Header from "../../components/header";

const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 72px;
    overflow: hidden;
`
const LampBox= styled.div`
    width: 100%;
    position: relative;
`
const ContentOne = styled(ContentBox)`
    padding: 80px 20px 34px;
    z-index: 97;
    position: relative;
`
const ContentTwo = styled.div`
    background: rgb(62,62,62,.4);
    margin-bottom: 50px;
    z-index: 97;
    position: relative;
`
const ContentTwoIn = styled(ContentBox)`
    position: relative;
    padding: 40px 20px;
`
const ItemImgStyle = styled.img`
    width: auto;
    height: 60%;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(50%);
    opacity: 0.1;
`
const Btn = styled.div`
    border-radius: 40px;
    background: #FCF800;
    padding: 24px 150px;
    letter-spacing: 3.2px;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 70px;
    @media screen and (max-width: 520px){ 
        padding: 24px;
    }
`
const TextBottom = styled(Text)`
    margin-bottom: ${({b}) => b ? b + 'px' : '46px'};
`
const Flex = styled.div`
    padding: 16px 10% 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const ImgItem = styled.img`
    width: 20%;
    height: auto;
`

function About() {
    return (
        <Wrapper>
            <Header />
            <LampBox>
                <ContentOne>
                    <Btn>
                        <Text size={40} hsize={26} color={'#000'}>Gentle Dragon</Text>
                    </Btn>
                    <TextBottom b={56} size={22} color={'#E9E010'}>Gentle Dragon Introduction</TextBottom>
                    <TextBottom b={34} size={14} color={'#fff'} justify={true}>
                        In this rapidly changing digital era, the spirit of individual expression and freedom has 
                        become the core pursuit for many. In response to this trend, we introduce the Gentle Dragon 
                        NFT series, inspired by the Bored Ape Yacht Club project. Similar to their approach, we've 
                        created a collection of dragons with a combination of various features and attributes, 
                        resulting in a series of unique and captivating dragon avatars. These distinctive dragon 
                        avatars serve not only as expressions of art but also as declarations of personality and freedom.
                    </TextBottom>
                    <TextBottom b={34} size={14} color={'#fff'} justify={true}>
                        Throughout countless cultural legends and historical records, dragons have consistently 
                        symbolized power and charm. They embody not just strength and majesty but also represent 
                        wisdom and the spirit of freedom. The image of dragons transcends time and cultural boundaries, 
                        becoming a universal language symbolizing the ability to surpass conventions and break limits.
                    </TextBottom>
                    <TextBottom b={34} size={14} color={'#fff'} justify={true}>
                        In Eastern cultures, dragons are symbols of auspiciousness, representing wisdom, power, and 
                        wealth. In Western mythology, dragons are formidable adversaries that heroes must conquer, 
                        symbolizing challenges and victories. This cultural diversity and difference are some of the 
                        reasons why we chose dragons as the theme for our NFT project. Through this initiative, 
                        we aim to blend these diverse cultural elements, creating a new and cross-cultural 
                        representation of Gentle Dragons.
                    </TextBottom>
                    <TextBottom b={34} size={14} color={'#fff'} justify={true}>
                        Through the Gentle Dragon NFT project, our goal is not only to create a series of unique 
                        digital artworks but also to inspire contemplation on freedom, courage, and individual 
                        expression. Each Gentle Dragon serves as a reinterpretation of tradition, a tribute to 
                        modern culture, and an exploration of endless possibilities for the future.
                    </TextBottom>
                </ContentOne>
                <Lamp style={{ top: '-50%', left: '-8%' }} />
                <Lamp style={{ bottom: '-30%', right: '6%' }} />
            </LampBox>
            <ContentTwo>
                <ContentTwoIn>
                    <TextBottom b={40} size={22} color={'#E9E010'}>Key Features</TextBottom>
                    <ul>
                        <li>
                            <TextBottom b={34} size={18} color={'#fff'} justify={true}>
                            1.Ever-changing Designs: Each Gentle Dragon is truly one-of-a-kind. We've crafted 
                            a range of features such as colors, expressions, attire, and backgrounds. By randomly 
                            combining these elements, we've created thousands of unique Gentle Dragon avatars 
                            with diverse and captivating designs.
                            </TextBottom>
                        </li>
                        <li>
                            <TextBottom b={34} size={18} color={'#fff'} justify={true}>
                            2.Digital Rarity and Uniqueness: Just like a dragon in reality is impossible to replicate, 
                            each Gentle Dragon NFT possesses a unique digital identity, ensuring their distinctiveness 
                            and scarcity value.
                            </TextBottom>
                        </li>
                        <li>
                            <TextBottom b={34} size={18} color={'#fff'} justify={true}>
                            3.Community and Interaction: Owning a Gentle Dragon NFT is not just owning a piece of art; 
                            it's also joining a community full of creativity and passion. Holders can engage in 
                            discussions, share creative ideas, and participate in exclusive community events.
                            </TextBottom>
                        </li>
                        <li>
                            <TextBottom b={34} size={18} color={'#fff'} justify={true}>
                            4.Cross-Industry Collaborations and Expansion: In the future, we plan to collaborate with 
                            brands and artists across various industries, extending the Gentle Dragon imagery into 
                            fashion, gaming, and physical merchandise. This will provide community members with 
                            even more exclusive experiences.
                            </TextBottom>
                        </li>
                    </ul>
                    <Flex>
                        <ImgItem src={dragon8} width={136} height={136} />
                        <ImgItem src={dragon9} width={136} height={136} />
                        <ImgItem src={dragon10} width={136} height={136} />
                        <ImgItem src={dragon11} width={136} height={136} />
                    </Flex>
                    <ItemImgStyle src={dragon7} />
                </ContentTwoIn>
            </ContentTwo>
            <LampBox>
                <ContentBox>
                    <TextBottom b={20} size={22} color={'#E9E010'}>Join Our Adventure</TextBottom>
                    <TextBottom b={100} size={14} color={'#fff'} justify={true}>
                        Embark on a journey into the fantastical world of Gentle Dragons and discover the dragon that 
                        best represents your personality. We are more than just an NFT project; we are a family united 
                        by a pursuit of creativity, individual expression, and community spirit. Join us now and together, 
                        let's embark on this unique digital adventure!
                    </TextBottom>
                    <TextBottom b={20} size={22} color={'#E9E010'}>The Origin of Gentle Dragons</TextBottom>
                    <TextBottom b={34} size={14} color={'#fff'} justify={true}>
                        The story of Gentle Dragons begins in a distant digital era, in a kingdom filled with fantasy and 
                        technology. Legend has it that a mysterious digital artist, utilizing blockchain technology and 
                        innovative digital media, gave birth to a unique tribe of dragons, endowing them with distinctive 
                        forms and life.
                    </TextBottom>
                    <TextBottom b={150} size={14} color={'#fff'} justify={true}>
                        This digital artist named this group of dragons Gentle Dragons because they not only possess a 
                        cool and impressive appearance but also embody the essence of digital art. Each Gentle Dragon 
                        is a one-of-a-kind digital masterpiece, shining as a beacon of art in the digital age.
                    </TextBottom>
                </ContentBox>
                <Lamp style={{ bottom: '-90%', right: '-10%' }} />
            </LampBox>
        </Wrapper>
    );
}

export default About;
