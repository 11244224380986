import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'
import Home from "./home";
import About from "./about";
import Cummunity from "./cummunity";

const WrapperApp = styled.div`
  background-color: #000;
`

function App() {
  return (
    <WrapperApp>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/cummunity" element={<Cummunity />} />
        <Route path="/" element={<Home />}/>
      </Routes>
    </WrapperApp>
  );
}

export default App;
