import styled from 'styled-components'

const lineHeightList = {
	12: 20,
	13: 20,
	14: 22,
	15: 24,
	16: 24,
	18: 28,
	20: 28,
	22: 35,
	24: 38,
	26: 42,
	30: 48,
	32: 38,
	36: 44,
	40: 48,
	44: 52,
	48: 58,
	54: 54,
	56: 68
}
const rem = ($px) => {
	return $px / 16 + 'rem'
  }
const Text = styled.p`
	font-family: Moul-Regular;
	color: ${ props => props.color };
	text-align: ${ props => props.justify? 'justify': 'center'};
	
	// pc
	@media screen and (min-width: 800px){ 
		font-size: ${ props => props.size + 'px'};
		line-height: ${ props => lineHeightList[props.size] + 'px'};
	}
	// H5
	@media screen and (max-width: 800px){
		font-size: ${ (props) => (props.hsize? props.hsize + 'px' : props.size + 'px')};
		line-height: ${ (props) => (props.hsize? lineHeightList[props.hsize] + 'px' : lineHeightList[props.size] + 'px')};
	}
`

const TabTextStyle = styled(Text)`
    padding: 8px 0px;
    margin: 12px 0 16px;
	@media screen and (max-width: 540px){
		margin: 0 0 12px;
	}
`

export {
	Text,
	TabTextStyle
}




