
import React from 'react';
import styled from 'styled-components'
import { Text } from '../../components/text';
import { ImgStyle, ContentBox } from '../../components/style';
import dragon12 from "../../assets/dragon12.png";
import Lamp from "../../components/lamp";
import Header from "../../components/header";
import jticon from "../../assets/jticon.png"

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    /* overflow-y: auto; */
`
const Auto = styled.div`
    height:100%;
    overflow-y: auto;
`
const ContentOne = styled(ContentBox)`
    /* height: 100vh; */
    padding: 80px 20px 74px 40px;
    /* overflow-y: auto; */
    position: relative;
    z-index: 97;
`
const ItemImgStyle = styled.img`
    width: auto;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 96;
`
const JtImgStyle = styled.img`
    width: 16px;
    height: 16px;
    position: absolute;
    left: -24px;
`
const Btn = styled.div`
    border-radius: 40px;
    background: #FCF800;
    padding: 24px 150px;
    letter-spacing: 3.2px;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 70px;
    @media screen and (max-width: 520px){ 
        padding: 24px;
    }
`
const TextBottom = styled(Text)`
    position: relative;
    padding-bottom: ${({b}) => b ? b + 'px' : '46px'};
`

function Cummunity() {
    return (
        <Wrapper>
            <Auto>
                <Header />
                <ContentOne>
                    <Btn>
                        <Text size={40} hsize={26} color={'#000'}>Gentle Dragon Community</Text>
                    </Btn>
                    <TextBottom b={56} size={22} color={'#E9E010'}>Gentle Dragon Treasure Alliance Club:</TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        Welcome to the passionate world of the Gentle Dragon Endless Treasure Alliance Club! Here are some 
                        unique privileges for you to enjoy in the Gentle Dragon universe:
                    </TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        <JtImgStyle src={jticon} />
                        1. Unique Appearance and Identity: As a member of the Endless Treasure Alliance Club, you will 
                        own a unique Gentle Dragon NFT with a distinctive appearance and scales, setting you apart 
                        within the alliance.
                    </TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        <JtImgStyle src={jticon} />
                        2. Priority Access: Members of the Endless Treasure Alliance enjoy the privilege of early 
                        access to unreleased content, artworks, and virtual goods. You'll have the opportunity to 
                        discover and acquire precious Gentle Dragon treasures before others.
                    </TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        <JtImgStyle src={jticon} />
                        3. Community Voting Rights: As a club member, you have the privilege to participate in 
                        community decision-making. Through voting, you can influence future events, the design of 
                        new characters, and the direction of the club's development.
                    </TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        <JtImgStyle src={jticon} />
                        4. Exclusive Event Participation: Endless Treasure Alliance members gain exclusive privileges 
                        to participate in special events, parties, and virtual celebrations. It's a chance to interact 
                        with other club members and share unique experiences.
                    </TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        <JtImgStyle src={jticon} />
                        5. Virtual Space Showcase: Club members will have the opportunity to showcase their NFT 
                        collections, artworks, or other creations in the virtual space of Gentle Dragons. Create 
                        a unique personal exhibition, showcasing your distinct footprint in the dragon world.
                    </TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        <JtImgStyle src={jticon} />
                        6. Special Rewards and Tokens: Endless Treasure Alliance members will enjoy special rewards, 
                        including digital currencies, community tokens, or other valuable virtual assets. You will 
                        also receive special club tokens for unique transactions and interactions within the community.  
                    </TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        <JtImgStyle src={jticon} />
                        7. Participate in Story Development: As a club member, you'll have the opportunity to participate 
                        in or influence the development of the storyline in the Gentle Dragon world. Your decisions and 
                        suggestions may shape the future of the entire dragon kingdom.
                    </TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        <JtImgStyle src={jticon} />
                        8. Regular Community Events: The Endless Treasure Alliance Club will host exclusive community 
                        events periodically, providing members with more interactive experiences.
                    </TextBottom>
                    <TextBottom b={40} size={14} color={'#fff'} justify={true}>
                        These privileges will allow Endless Treasure Alliance members to experience a unique, precious, 
                        and enjoyable journey in the world of Gentle Dragons. Club members will explore, create, and 
                        share in this wondrous world full of endless treasures alongside their fellow dragon enthusiasts.
                    </TextBottom>
                </ContentOne>
            </Auto>
            <ItemImgStyle src={dragon12} />
            <Lamp style={{ top: '-30%', left: '-6%' }} />
            <Lamp style={{ bottom: '-10%', right: '-4%' }} />
        </Wrapper>
    );
}

export default Cummunity;
