import React from 'react';
import styled, { keyframes } from 'styled-components';
import 'animate.css';
import roll0 from "../../assets/roll0.png";
import roll1 from "../../assets/roll1.png";
import roll2 from "../../assets/roll2.png";
import roll3 from "../../assets/roll3.png";
import roll4 from "../../assets/roll4.png";
import roll5 from "../../assets/roll5.png";
import roll6 from "../../assets/roll6.png";
import roll7 from "../../assets/roll7.png";
import roll8 from "../../assets/roll8.png";
import roll9 from "../../assets/roll9.png";
import roll10 from "../../assets/roll10.png";

const Wrapper = styled.div`
    z-index: 97;
    position: relative;
`
const ScrollListContainerL = styled.div`
    overflow: hidden;
    margin-bottom: 40px;
`;
const ScrollListContainerR = styled.div`
    overflow: hidden;
`;
const slide = keyframes`
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 25px;
    animation: ${slide} 20s linear infinite;
`;
const List = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 25px;
    animation: ${slide} 30s linear infinite;
`;
const slideR = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;
const ContainerR = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 25px;
    animation: ${slideR} 30s linear infinite;
`;
// const Item = styled.div`
//     width: 360px;
//     height: 360px;
// `
const ImgStyle = styled.img`
    width: 360px;
    height: 360px;
    @media screen and (max-width: 1200px){ 
        width: 300px;
        height: 300px;
    }
    @media screen and (max-width: 520px){ 
        width: 140px;
        height: 140px;
    }
`


const InfiniteScroll = () => {
    const rollImgL = [
        roll3,
        roll4,
        roll0,
        roll1,
        roll2,
        roll3,
        roll4,
        roll0,
        roll1,
        roll2,
        roll3,
        roll4,
        roll0,
        roll1,
        roll2,
    ]
    const rollImgR = [
        roll8,
        roll9,
        roll10,
        roll5,
        roll6,
        roll7,
        roll8,
        roll9,
        roll10,
        roll5,
        roll6,
        roll7,
        roll8,
        roll9,
        roll10,
    ]
    return (
        <Wrapper>
            <ScrollListContainerL>
                <Container>
                    {rollImgL.map((img, index) => (
                        <ImgStyle src={img} />
                    ))}
                </Container>
            </ScrollListContainerL>
            <ScrollListContainerR>
                <ContainerR>
                    {rollImgR.map((img, index) => (
                        <ImgStyle src={img} />
                    ))}
                </ContainerR>
            </ScrollListContainerR>
        </Wrapper>
    );
};

export default InfiniteScroll;
