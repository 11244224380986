import styled from 'styled-components'

const Flex = styled.div`
    display: flex;
    align-items: center;
`
const FlexGap = styled(Flex)`
    gap: ${(props) => (props.gap? props.gap + 'px' : '10px')};
    @media screen and (max-width: 600px) {
        gap: ${(props) => (props.hgap? props.hgap + 'px' : props.gap + 'px')}
    }
`

const FlexColumn = styled(Flex)`
    flex-direction: column;
`

const FlexColumnStart = styled(FlexColumn)`
    align-items: flex-start;
    gap: ${(props) => (props.gap? props.gap + 'px': '0px')};
`

const SpaceBetween = styled(Flex)`
    justify-content: space-between;
`

const BetweenNoCenter = styled(SpaceBetween)`
    width: 100%;
    height: 60px;
    align-items: flex-start;
    @media screen and (max-width: 500px) {
        height: 100px;
        flex-flow: column;
        row-gap: 6px;
    }
`

const Item = styled.div`
    flex: 1;
    ${props => props.left ? 'text-align: left;': ''}
`

export {
    Flex,
    FlexGap,
    FlexColumn,
    FlexColumnStart,
    SpaceBetween,
    BetweenNoCenter,
    Item
}