const links = {
    'twitter': 'https://twitter.com/gentle_dragons',
    'tg': 'https://t.me/Gentle_Dragons',
    'uniSwap': ''
}

export function goLink(text){
    let link = links[text]
    if(link) {
        window.open( link, '_blank')
    }
}