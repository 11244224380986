import React, { useEffect, useState } from 'react';
import 'animate.css';
import styled from 'styled-components';
import dragon1 from "../../assets/dragon1.png";
import dragon2 from "../../assets/dragon2.png";
import dragon3 from "../../assets/dragon3.png";
import dragon4 from "../../assets/dragon4.png";
import dragon5 from "../../assets/dragon5.png";

const Animated = styled.div`
    height: 100%;
    opacity: 0;
    animation: fadeInUp ease-in .6s forwards;
    display: flex;
    align-items: flex-end;
    margin: 0 -1.2%;
    &.animate1{
        height: 100%;
        position: relative;
        z-index: 97;
    }
    &.animate2{
        height: 86%;
        position: relative;
        z-index: 96;
    }
    &.animate3{
        height: 86%;
        position: relative;
        z-index: 96;
    }
    &.animate4{
        height: 70%;
        position: relative;
        z-index: 95;
    }
    &.animate5{
        height: 70%;
        position: relative;
        z-index: 95;
    }
    @media screen and (max-width: 1420px){ 
        margin: 0 -3%;
    }
    @media screen and (max-width: 520px){ 
        margin: 0 -10%;
    }
`
const AnimatedImage = styled.img`
    height: 100%;
    &:hover {
        animation: wobble 1s infinite; /* 添加 pulse 效果 */
        opacity: 1;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
`;

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
`;

const ImageSequence = () => {
  const images = [dragon1, dragon2,dragon3,dragon4,dragon5];
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleIndex((prevIndex) => {
        if (prevIndex < images.length - 1) {
          return prevIndex + 1;
        } else {
          clearInterval(intervalId);
          return prevIndex;
        }
      });
    }, 1000); // Change the delay according to your preference

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <ImageContainer>
        <Animated className={`animate4 animate__animated animate__bounceInUp animate__delay-2s`}>
            <AnimatedImage
                src={dragon4}
                alt={`Image 1`}
            />
        </Animated>
        <Animated className={`animate2 animate__animated animate__bounceInUp animate__delay-1s`}>
            <AnimatedImage
                src={dragon2}
                alt={`Image 1`}
            />
        </Animated>
        <Animated className={`animate1 animate__animated animate__bounceInUp animate__delay-0.5s`}>
            <AnimatedImage
                src={dragon1}
                alt={`Image 1`}
            />
        </Animated>
        <Animated className={`animate3 animate__animated animate__bounceInUp animate__delay-1s`}>
            <AnimatedImage
                src={dragon3}
                alt={`Image 1`}
            />
        </Animated>
        <Animated className={`animate5 animate__animated animate__bounceInUp animate__delay-2s`}>
            <AnimatedImage
                src={dragon5}
                alt={`Image 1`}
            />
        </Animated>
    </ImageContainer>
  );
};

export default ImageSequence;
